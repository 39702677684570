import { Position, Toaster } from "@blueprintjs/core"

export function created(message: string, id: string) {
	success(message, id)
}

export function success(message: string, id: string) {
	const toast = Toaster.create({ position: Position.BOTTOM_RIGHT })
	toast.show({
		message: message,
		intent: "success",
		icon: "tick",
	}, id)
}

export function deleted(message: string, id: string) {
	const toast = Toaster.create({ position: Position.BOTTOM_RIGHT })
	toast.show({
		message: message,
		intent: "danger",
		icon: "trash",
	}, id)
}