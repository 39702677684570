import React from 'react';
import yeetpay from '../../static/yeetpay.png';

interface props {
	large: boolean;
}

export class Banner extends React.Component<props, never> {

	public static defaultProps = {
		large: false
	};

	render() {
		const imageStyle = {
			width: 36,
			margin: "0 10px 0 0"
		}

		const titleStyle = {
			margin: "0 0 0 0",
			fontSize: "default"
		}

		if (this.props.large) {
			imageStyle.margin = "15px 10px 0 20px"
			imageStyle.width = 50
			titleStyle.fontSize = "60px"
		}

		return (
			<div style={{ display: "flex", flexDirection: "row" }}>
				<div>
					<img src={yeetpay} style={imageStyle} alt="yeetpay" />
				</div>
				<h1 style={titleStyle}>YeetPay</h1>
			</div >
		)
	}
}