import React, { FunctionComponent } from "react";
import { useQuery } from "react-query";
import { ErrorMessage, Layout, Loading, Subtitle } from "./helpers";
import { Callout, Card, Elevation } from "@blueprintjs/core";
import { Workspaces } from "../../client/workspaces";
import { useTranslation } from "react-i18next";
import { InvitationsDisplay } from "../common/invitations";
import { WorkspaceForm, WorkspaceFormPopUpButton } from "../common/workspaces/form";
import { WorkspaceListDisplay } from "../common/workspaces/list";


export default function Setup() {
	const { data, error, isFetching } = useQuery(Workspaces.Get());

	if (isFetching) {
		return <Loading />

	} else if (error) {
		return <ErrorMessage />

	} else if (!data || !data.data) {
		return <CreateWorkspace />

	} else {
		return <SelectWorkspace workspaces={data.data} />
	}
}

type props = {
	workspaces: Array<any>
}

const SelectWorkspace: FunctionComponent<props> = ({ workspaces }) => {
	const { t } = useTranslation()

	return (
		<Layout title={t("init.setup.welcome")}>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<div>
					<InvitationsDisplay />
				</div>
				<Subtitle subtitle={t("init.setup.select")} />
				<div style={{ maxWidth: "400px" }}>
					<WorkspaceListDisplay workspaces={workspaces} />
				</div>
			</div>
		</Layout >)
}

function CreateWorkspace() {
	const { t } = useTranslation()

	return (
		<Layout title={t("init.setup.welcome")}>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<InvitationsDisplay />
				<Subtitle subtitle={t("init.setup.create_workspace")} />
				<div style={{ alignItems: "center" }}>
					<div>
						<Card elevation={Elevation.TWO} style={{ width: 450 }}>
							<Callout title={t("init.setup.no_workspace_access")} intent="primary">
								{t("init.setup.no_workspace_access_desc")}
							</Callout>

							<h3 style={{ textAlign: "center", margin: "30px 0 15px 0" }}>
								{t("init.setup.what_is_a_workspace")}
							</h3>
							<p>{t("init.setup.what_is_a_workspace_desc")}</p>

							<WorkspaceFormPopUpButton large />
						</Card>
					</div>
				</div >
			</div>
		</Layout >
	)
}

