import Setup from './pages/init/setup';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Loading, NotFound } from './pages/init/helpers';
import ModeSelector from './pages/init/mode_selector';
import { queryClient } from './client/client';
import { FetchUser } from './pages/common/user';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';


interface IAppState {
	user: any | null
	setUser: (u: any) => void
}

export const AppStateCtx = React.createContext<IAppState>({
	user: null,
	setUser: () => { console.error("setUser undefined") }
})

export const useAppState = () => useContext(AppStateCtx);


export default function App() {
	const [user, setUser] = useState(null)
	const state = { user, setUser }

	return (
		<QueryClientProvider client={queryClient}>
			<AppStateCtx.Provider value={state}>
				<div>
					<FetchUser />
					{user !== undefined && user !== null
						? <BrowserRouter>
							<ScrollToTop />
							<Routes>
								<Route path="/" element={<Setup />} />
								<Route path="/:workspaceID/*" element={<ModeSelector />} />
								<Route element={<NotFound />} />
							</Routes>
						</BrowserRouter>
						: <Loading />
					}
				</div>
			</AppStateCtx.Provider>
		</QueryClientProvider>
	);
}

function ScrollToTop() {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return null;
}