import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { Workspaces } from "../../../client/workspaces"
import { ErrorConnection } from "../errors"
import { Loading } from "../loading"
import { WorkspaceFormPopUpButton } from "./form"


export function WorkspacesList(props: { currentWorkspace: any }) {
	const { data, error, isFetching } = useQuery(Workspaces.Get())

	if (isFetching) {
		return <Loading dataName="workspaces" />
	} else if (error) {
		return <ErrorConnection />
	} else {
		return <WorkspaceListDisplay workspaces={data?.data} current={props.currentWorkspace} />
	}
}

export function WorkspaceListDisplay(props: { workspaces: Array<any>, current?: any }) {
	const elems = props.workspaces.map(ws => (
		<div key={ws.workspace_id}>
			<li  >
				<ListElem current={props.current} ws={ws} />
			</li>
			<li className="bp3-menu-divider"></li>
		</div>
	))

	return (
		<ul className="bp3-menu bp3-elevation-1 bp3-large" >
			{elems}
			<li>
				<WorkspaceFormPopUpButton />
			</li>
		</ul >
	)
}

function ListElem(props: { current?: any, ws: any }) {
	const content = (
		<div className="bp3-ui-text" style={{ maxWidth: "380px" }}>
			<h4 className="bp3-heading" style={{ margin: "0 0 0 0" }}>
				{props.ws.name}
			</h4>

			{props.ws.description !== `` &&
				<div className="bp3-text-muted" style={{ margin: "10px 0 0 0" }}>
					{props.ws.description}
				</div>
			}
		</div>
	)

	if (props.current !== undefined && props.current === props.ws.workspace_id) {
		return (
			<span className="bp3-menu-item bp3-disabled">
				{content}
			</span>
		)
	} else {
		return (
			<Link to={`/${props.ws.workspace_id}`} className="bp3-menu-item">
				{content}
			</Link>
		)
	}
}