import { Button, Callout } from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { useMutation } from "react-query";
import { queryClient, remove } from "../../client/client";
import { deleted } from "./toasts";

type clickHandler = () => void

type props = {
	title?: string
	message: string
	deletedMessage: string
	deleteUrl: string
	confirmButton?: string
	resource?: string
	onConfirm?: () => void
	notificationID?: string
	elem?: (onclick: clickHandler) => JSX.Element
}

export function ConfirmDelete(props: props) {
	const [isOpen, setOpen] = useState(false);

	const mutation = useMutation(() => remove(props.deleteUrl), {
		onSuccess: () => {
			if (props.resource !== undefined) {
				queryClient.invalidateQueries(props.resource)
				deleted(props.deletedMessage, props.resource)
			} else {
				if (props.notificationID !== undefined) {
					deleted(props.deletedMessage, props.notificationID)
				}
				if (props.onConfirm !== undefined) {
					props.onConfirm()
				}
			}
		}
	})

	function open() {
		mutation.reset()
		setOpen(true)
	}

	const content = (
		<div>
			<div className="bp3-text-large">
				<strong>
					{props.title !== undefined ? props.title : "Confirm deletition"}
				</strong>
			</div>

			<div style={{ margin: "10px 0 10px 0" }}>
				<p>{props.message}</p>
			</div>

			{mutation.isError &&
				<Callout intent="danger" title="Request failed">
					Check your internet connection and try again.
				</Callout>
			}

			<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
				<Button
					className={Classes.POPOVER2_DISMISS}
					style={{ marginRight: 10 }}>
					Cancel
				</Button>
				<Button
					onClick={() => { mutation.mutate() }}
					intent="danger">
					{props.confirmButton !== undefined ? props.confirmButton : "Delete"}
				</Button>
			</div>
		</div>
	)

	let btn = <Button minimal small intent="danger" onClick={open}>delete</Button>
	if (props.elem !== undefined) {
		btn = props.elem(open)
	}

	return (
		<div>
			<Popover2
				onClose={() => setOpen(false)}
				content={content}
				isOpen={isOpen}
				popoverClassName={Classes.POPOVER2_CONTENT_SIZING}>
				{btn}
			</Popover2>
		</div>
	)

}

// type confirmProps = {
// 	onConfirm: () => void
// 	message?: string
// 	title?: string
// 	intent?: Intent
// }

// export function Confirm({ onConfirm, message, title, intent }: confirmProps) {
// 	const [isOpen, setOpen] = useState(false);

// 	const content = (
// 		<div>
// 			<div className="bp3-text-large">
// 				<strong>Confirm deletition</strong>
// 			</div>

// 			<div style={{ margin: "10px 0 10px 0" }}>
// 				<p>{props.message}</p>
// 			</div>

// 			{mutation.isError &&
// 				<Callout intent="danger" title="Request failed">
// 					Check your internet connection and try again.
// 				</Callout>
// 			}

// 			<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
// 				<Button
// 					className={Classes.POPOVER2_DISMISS}
// 					style={{ marginRight: 10 }}>
// 					Cancel
// 				</Button>
// 				<Button
// 					onClick={onConfirm}
// 					intent={intent}>
// 					Delete
// 				</Button>
// 			</div>
// 		</div>
// 	)

// 	return (
// 		<div>
// 			<Popover2
// 				onClose={() => setOpen(false)}
// 				content={content}
// 				isOpen={isOpen}
// 				popoverClassName={Classes.POPOVER2_CONTENT_SIZING}>
// 				{btn}
// 			</Popover2>
// 		</div>
// 	)
// }