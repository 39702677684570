import { Callout } from "@blueprintjs/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

export function ErrorConnection() {
	const { t } = useTranslation()

	return (
		<Callout intent="danger" title="connection error">
			{t("common.errors.check_connection")}
		</Callout>
	)
}

export function ErrorNotFound(props: { message?: string }) {
	const { t } = useTranslation()

	return (
		<Callout title={t("common.errors.not_found")}>
			{props.message !== undefined
				? <span>{props.message}</span>
				: <span>{t("common.errors.not_found_msg")}</span>
			}
		</Callout>
	)
}

export function ErrorUnknow(props: { msg: string, description?: string }) {
	const { t } = useTranslation()

	return (
		<Callout title={t("common.errors.ooops")} intent="danger" >
			<strong>{props.msg}</strong>

			<div>
				{props.description !== undefined && props.description !== null &&
					<span>{props.description}</span>
				}
				{props.description === undefined || props.description === null &&
					<Trans i18nKey="common.errors.try_refresh">
						Try to refresh the page
					</Trans>

				}
			</div>
		</Callout >
	)
}


