import { Button, Callout, Dialog, H3, InputGroup, NumericInput, Slider, TextArea } from "@blueprintjs/core"
import { Form, Formik, FormikErrors } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { WriteResponse } from "../../../client/client"
import { Workspaces } from "../../../client/workspaces"
import { FormField } from "../form"
import { created } from "../toasts"
import { WeeklyPlanning } from "./weekly_planning"


export function WorkspaceFormPopUpButton(props: { workspace?: any, large?: boolean }) {
	const [isOpen, setOpen] = useState(false)
	const navigate = useNavigate()
	const title = props.workspace === undefined
		? "New Workspace"
		: "Edit Workspace"

	function onDone(workspace: any) {
		if (props.workspace === undefined) {
			navigate(`/${workspace.workspace_id}`)
		}
	}

	return (
		<>
			{props.large
				? <Button
					onClick={() => setOpen(true)}
					icon="plus"
					fill
					intent="success" large>
					{title}
				</Button>
				: <span
					onClick={() => setOpen(true)}
					className="bp3-menu-item bp3-icon-plus bp3-intent-primary">
					{title}
				</span>
			}
			<Dialog
				lazy
				style={{ width: "600px" }}
				isOpen={isOpen}
				title={title}
				onClose={() => setOpen(false)}>

				<div className="bp3-dialog-body">
					<WorkspaceForm
						onDone={onDone}
						onCancel={() => setOpen(false)}
						submitRight
						help
						submitLabel={props.workspace === undefined ? "Create" : "Update"} />
				</div>

			</Dialog>
		</>
	)
}


const defaultWeeklyPlanning = [
	{ day: 1, hours: 8 },
	{ day: 2, hours: 8 },
	{ day: 3, hours: 8 },
	{ day: 4, hours: 8 },
	{ day: 5, hours: 8 }]

type props = {
	onCancel?: () => void
	workspace?: any
	submitLabel: string
	cancelLabel?: string
	onDone?: (workspace: any) => void
	submitRight?: boolean
	help?: boolean
}

export function WorkspaceForm({ onCancel, workspace, submitLabel, cancelLabel, onDone, submitRight, help }: props) {
	const { t } = useTranslation()

	let initValues = {
		name: '',
		description: '',
		weekly_planning: defaultWeeklyPlanning,
		payroll_day: 7,
	}
	if (workspace !== undefined) {
		initValues = {
			name: workspace.name,
			description: workspace.description,
			weekly_planning: workspace.weekly_planning,
			payroll_day: workspace.payroll_day,
		}
	}

	async function onSubmit(values: any, actions: any) {
		let res: WriteResponse
		if (workspace !== undefined) {
			res = await Workspaces.Workspace.Update(workspace.workspace_id, values)
		} else {
			res = await Workspaces.Create(values)
		}

		if (res.validationErrors) {
			actions.setErrors(res.validationErrors)
		} else {
			created(t("admin.settings.general.workspace_form.update_success"), "admin.settings.general.workspace")
			Workspaces.Workspace.Refresh(res.data?.workspace_id)
			Workspaces.Refresh()
			if (onDone !== undefined) {
				onDone(res.data)
			}
		}
	}

	return (
		<Formik initialValues={initValues} onSubmit={onSubmit}>
			{({
				values,
				errors,
				handleChange,
				isSubmitting,
				setFieldValue,
			}) => (
				<Form>
					<Fields
						help={help}
						values={values}
						setFieldValue={setFieldValue}
						handleChange={handleChange}
						errors={errors} />

					<div style={{ display: "flex", justifyContent: submitRight ? "flex-end" : "flex-start", margin: "10px 0 0 0" }}>
						{onCancel !== undefined &&
							<Button
								onClick={onCancel}
								disabled={isSubmitting}
								style={{ margin: "0 10px 0 0" }}>
								{cancelLabel !== undefined ? cancelLabel : "Cancel"}
							</Button>
						}
						<Button type="submit" intent="success" disabled={isSubmitting}>
							{submitLabel}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}


type fieldsProps = {
	values: any
	handleChange: (e: React.ChangeEvent<any>) => void
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
	errors: FormikErrors<any>
	help?: boolean
}

function Fields({ values, handleChange, setFieldValue, errors, help }: fieldsProps) {
	const { t } = useTranslation()

	return (
		<div>
			<div style={{ display: "flex" }}>
				<div style={{ flexGrow: 1, margin: "0 20px 0 0", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
					<div>
						<div style={{ display: "flex" }}>
							<FormField label={t("admin.settings.general.workspace_form.name")} name="name" errors={errors}>
								<InputGroup
									placeholder="My Company Name"
									id="name"
									large
									fill
									value={values.name}
									onChange={handleChange}
								/>
							</FormField>
						</div>
						<div style={{ display: "flex" }}>
							<FormField label={t("admin.settings.general.workspace_form.description")} name="description" errors={errors}>
								<TextArea
									id="description"
									value={values.description}
									onChange={handleChange}
									large
									fill />
							</FormField>
						</div>
						<div style={{ display: "flex" }}>
							<FormField
								label={t("admin.settings.general.workspace_form.payroll_day")}
								name="payroll_day"
								help={t("admin.settings.general.workspace_form.payroll_day_help")}
								errors={errors}>
								<NumericInput
									min={1}
									max={31}
									fill
									value={values.payroll_day}
									onValueChange={(i) => setFieldValue('payroll_day', i)} />
							</FormField>
						</div>
					</div>
				</div>
				<div style={{ display: "flex" }}>
					<FormField label={t("admin.settings.general.workspace_form.weekly_planning")} name="weekly_planning" errors={errors}>
						<div style={{ display: "inline-block" }}>
							<WeeklyPlanning
								id="weekly_planning"
								values={values.weekly_planning}
								setFieldValue={setFieldValue} />
						</div>
					</FormField>
				</div>
			</div>
			{help &&
				<Callout
					style={{ margin: "0 0 13px 0" }}
					intent="primary"
					title="You can always change theses values afterward">
					If you are not sure what to choose, dont worry! You can always change theses value
					in the "Settings" menu.
				</Callout>
			}
		</div>
	)
}
