import { Button, Callout, Card, Classes, Colors, Dialog } from "@blueprintjs/core";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { User } from "../../client/user";
import { Workspaces } from "../../client/workspaces";
import { InvitationRoleTag } from "../admin/settings/admins/invitations";
import { deleted, success } from "./toasts";


function Item(props: { invitation: any }) {
	const inv = props.invitation
	const [isOpen, setOpen] = useState(false)

	const desc = (
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<div>
				<span style={{ fontWeight: 500, color: Colors.BLUE1, fontSize: "1.1em" }}>
					{inv.workspace.name}
				</span>

				{inv.workspace.description !== undefined &&
					<p>
						{inv.workspace.description}
					</p>
				}
			</div>
			<div>
				role: <InvitationRoleTag invitation={inv} />
			</div>
		</div>
	)

	return (
		<>
			<div className="bp3-menu-item" onClick={() => setOpen(true)}>
				<div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
					{desc}

				</div>
			</div >
			<div>
				{isOpen &&
					<Confirm invitation={inv} onClose={() => setOpen(false)} />
				}
			</div>
		</>
	)
}

function Confirm(props: { invitation?: any, onClose: () => void }) {
	const [isLoading, setLoading] = useState(false)
	const inv = props.invitation
	const navigate = useNavigate()

	if (inv === undefined || inv === null) {
		return <span />
	}

	async function accept() {
		setLoading(true)
		await User.Invitations.Accept(inv.invitation_id)
		setLoading(false)
		User.Invitations.Refresh()
		Workspaces.Refresh()
		success("Invitation accepted", "invitations.accepted")
		navigate(`/${inv.workspace.workspace_id}`)
	}

	async function refuse() {
		setLoading(true)
		await User.Invitations.Refuse(inv.invitation_id)
		setLoading(false)
		User.Invitations.Refresh()
		deleted("Invitation refused", "invitations.refused")
	}

	return (
		<Dialog
			isOpen={props.invitation !== undefined && props.invitation !== null && !isLoading}
			canEscapeKeyClose={!isLoading}
			canOutsideClickClose={!isLoading}
			title="Accept invitation"
			onClose={() => props.onClose()} >

			<div className={Classes.DIALOG_BODY}>
				<div>
					<div style={{ margin: "0 0 10px 0" }}>
						Invitation from: &nbsp;
						<span style={{ color: Colors.BLUE1, fontWeight: 500 }}>
							{inv.creator.first_name} {inv.creator.last_name}
						</span>
					</div>
					<div>
						To join the workspace:
						<Card style={{ margin: "5px 0 0 0 ", padding: "10px 15px 10px 15px" }}>
							<div>
								<div style={{ fontWeight: 500, fontSize: "1.1em", color: Colors.BLUE1 }}>
									{inv.workspace.name}
								</div>
								{inv.workspace.description !== undefined &&
									<p style={{ margin: "5px 0 0 0" }}>
										{inv.workspace.description}
									</p>
								}
							</div>
						</Card>
						<div style={{ margin: "10px 0 0 0" }}>
							as <InvitationRoleTag invitation={inv} />
						</div>
					</div>

					{inv.message !== undefined &&
						<div style={{ margin: "20px 0 0 0 " }}>
							Message:
							<Card style={{ margin: "5px 0 0 0 " }}>
								<div className="bp3-text-large">
									{inv.message}
								</div>
							</Card>
						</div>
					}
				</div>
			</div>

			<div className={Classes.DIALOG_FOOTER}>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<div>
						<Button onClick={() => props.onClose()} disabled={isLoading}>
							Cancel
						</Button>
					</div>
					<div style={{ margin: "0 0 0 10px" }}>
						<Button intent="success" disabled={isLoading} onClick={accept}>
							Accept
						</Button>
					</div>
					<div style={{ margin: "0 0 0 10px" }}>
						<Button intent="danger" disabled={isLoading} onClick={refuse}>
							Refuse
						</Button>
					</div>
				</div>
			</div>
		</ Dialog >
	)
}

export function InvitationsDisplay() {
	const { data, error, isFetching } = useQuery(User.Invitations.All())

	if (error || isFetching) {
		return <div />
	}
	if (data?.data === undefined || data.data === null) {
		return <div />
	}

	const invitations = data.data
	if (!(Array.isArray(invitations)) || invitations.length == 0) {
		return <span />
	}

	return (
		<Card elevation={2} style={{ margin: "0 0 40px 0" }}>
			<Callout
				icon="envelope"
				intent="success"
				title="You are invited to join a workspace">
				Accept the invitation to join the workspace.
			</Callout>

			<ul className="bp3-menu bp3-elevation-1" style={{ margin: "20px 0 0 0 " }}>
				{invitations.map((inv) =>
					<Item
						key={inv.invitation_id}
						invitation={inv} />)
				}
			</ul>
		</Card>
	)

}