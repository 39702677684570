import { Button, Classes, Colors, Dialog, Icon, IconName } from "@blueprintjs/core";
import { Component, FunctionComponent } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Banner } from "./banner";
import { User } from "./user";
import { WorkspacesList } from "./workspaces/list";

type wsProps = {
	workspace: any
}

type menuProps = {
	workspace: any
	tabs: JSX.Element
}

const menuBarStyle = {
	background: Colors.LIGHT_GRAY5,
	padding: "16px 0 0 0",
	margin: "0 0 32px 0",
	borderBottom: "1px solid",
	borderColor: Colors.GRAY5
}

const MenuBar: FunctionComponent<menuProps> = ({ workspace, tabs }) =>
	<div style={menuBarStyle}>
		<div style={{ padding: "10px 32px 0 32px", display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Banner />
					<div style={{ padding: "0 0 0 12px" }}>
						<Workspace workspace={workspace} />
					</div>
				</div>
				<div style={{ minWidth: 100 }}>
					<User />
				</div>
			</div>
			<div style={{ margin: "16px 0 -1px 0" }}>
				{tabs}
			</div>
		</div>
	</div>


type workspaceState = {
	isOpen: boolean
}

class Workspace extends Component<wsProps, workspaceState> {

	public state: workspaceState = { isOpen: false }

	handleOpen = () => this.setState({ isOpen: true });

	handleClose = () => this.setState({ isOpen: false });

	handleDone = () => this.handleClose();

	render() {
		return (
			<div>
				<Dialog
					lazy
					isOpen={this.state.isOpen}
					title="Select a Workspace"
					onClose={() => this.handleClose()}
				>
					<div className={Classes.DIALOG_BODY}>
						<WorkspacesList currentWorkspace={this.props.workspace} />
					</div>
				</Dialog>

				<Button
					large
					minimal
					intent="primary"
					style={{ fontSize: "2em", fontWeight: 600 }}
					onClick={this.handleOpen}>{this.props.workspace.name}</Button>
			</div>
		)
	}
}

export const Layout: FunctionComponent<menuProps> = ({ children, workspace, tabs }) =>
	<div className="bp3-ui-text">
		<MenuBar workspace={workspace} tabs={tabs} />
		<div style={{ display: "flex", justifyContent: "center" }}>
			<div style={{ minWidth: 1180, maxWidth: 1730, flexGrow: 1 }}>
				<div style={{ margin: "0 10px 0 10px" }}>
					{children}
				</div>
			</div>
		</div>
		<div style={{ height: "200px" }}>

		</div>
	</div>

type tabItemProps = {
	path: string
	name: string
	icon: IconName
	to: string
}

export const TabNavItem: FunctionComponent<tabItemProps> = ({ path, name, icon, to }) => {
	const currentTab = (
		<li className="bp3-tab" role="tab" aria-selected="true">
			<Icon icon={icon} />&nbsp; {name}
		</li>
	)

	const otherTab = (
		< li className="bp3-tab" role="tab" >
			<Link to={to}>
				<span style={{ color: Colors.GRAY1 }}>
					<Icon icon={icon} />&nbsp; {name}
				</span>
			</Link>
		</li >
	)

	return (
		<Routes>
			<Route path={path} element={currentTab} />
			<Route path="*" element={otherTab} />
		</Routes>
	)
}