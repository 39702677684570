import { Card, Colors, Divider, NumericInput } from "@blueprintjs/core"

type props = {
	id: string
	values: Array<any>
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export function WeeklyPlanning({ id, values, setFieldValue }: props) {
	const days = [0, 1, 2, 3, 4, 5, 6]
	const valueMap = days.map((day) => {
		const selectHours = (acc: number, v: any) => v.day === day ? v.hours : acc
		return {
			day: day,
			hours: values.reduce(selectHours, 0)
		}
	})

	function change(day: number) {
		return (e: number) => {
			const values = valueMap.map((current) =>
				current.day !== day ? current : {
					day: day,
					hours: e
				}
			)
			setFieldValue(id, values)
		}
	}

	return (
		<Card>
			<div>

				<div style={{ flexGrow: 1, display: "flex", color: Colors.BLUE1, fontWeight: 600 }}>
					<div style={{ width: "130px" }}>Day</div>
					<div style={{ width: "80px" }}>
						Hours
					</div>
				</div>
				<Divider />

				<DaylyHoursInput day="Monday" dayID={1} value={valueMap[1].hours} handleChange={change(1)} />
				<DaylyHoursInput day="Tuesday" dayID={2} value={valueMap[2].hours} handleChange={change(2)} />
				<DaylyHoursInput day="Wednesday" dayID={3} value={valueMap[3].hours} handleChange={change(3)} />
				<DaylyHoursInput day="Thursday" dayID={4} value={valueMap[4].hours} handleChange={change(4)} />
				<DaylyHoursInput day="Friday" dayID={5} value={valueMap[5].hours} handleChange={change(5)} />
				<DaylyHoursInput day="Saturday" dayID={6} value={valueMap[6].hours} handleChange={change(6)} />
				<DaylyHoursInput day="Sunday" dayID={0} value={valueMap[0].hours} handleChange={change(0)} />

				<div style={{ textAlign: "right", color: Colors.BLUE1, fontWeight: 600, margin: "12px 0 0 0" }}>
					{valueMap.reduce((acc, e) => acc + e.hours, 0)} hours
				</div>
			</div>
		</Card>
	)
}

function DaylyHoursInput(props: { day: string, dayID: number, value: number, handleChange: (e: number) => void }) {
	return (
		<div style={{ flexGrow: 1, display: "flex", margin: "8px 0 0 0" }}>
			<div style={{ width: "130px" }}>{props.day}</div>

			<div style={{ width: "80px" }}>
				<NumericInput min={0} max={24} fill value={props.value} onValueChange={props.handleChange} />
			</div>

		</div >
	)
}

