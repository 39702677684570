import { Colors, H2, ProgressBar, Spinner } from "@blueprintjs/core";
import { Trans } from "react-i18next";


export function Loading(props: { dataName?: string, text?: string }) {
	if (props.dataName === undefined && props.text === undefined) {
		return <ProgressBar intent="primary" />
	}

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<div style={{ margin: "0 0 10px 0" }} className="bp3-text-large">
				{props.text != null &&
					<>{props.text}</>
				}
				{props.text === undefined && props.dataName !== undefined &&
					<Trans i18nKey="common.loading.loading" >
						Loading {{ dataName: props.dataName }}.
					</Trans>
				}
			</div>
			<ProgressBar intent="primary" />
		</div>
	)
}

export function LoadingPage() {
	return (
		<div>
			<div className="bp3-skeleton" style={{ height: 60 }} />

			<div style={{ display: "flex", margin: "10px 0 0 0" }}>
				<div className="bp3-skeleton" style={{ flexGrow: 2, height: 220 }} />

				<div style={{ display: "flex", justifyContent: "center", flexGrow: 4 }}>
					<div style={{ margin: "30px 0 0 0", display: "flex", flexDirection: "column", alignItems: "center" }}>

						<H2 style={{ color: Colors.GRAY2 }}>Loading the page, please wait</H2>

						<div style={{ color: Colors.GRAY1 }}>If this is taking too long, check your internet connection and reload the page.</div>

						<div style={{ margin: "20px 0 0 0" }}>
							<Spinner size={80} />
						</div>
					</div>
				</div>
			</div>

			<div className="bp3-skeleton" style={{ height: 60, margin: "10px 0 0 0 " }} />
			<div className="bp3-skeleton" style={{ height: 60, margin: "10px 0 0 0 " }} />
		</div >
	)
}

