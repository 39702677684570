import { Button, Callout, Colors, Spinner } from "@blueprintjs/core"
import React, { Component, FunctionComponent } from "react"

type formFieldProps = {
	label: string
	name: string
	errors?: any
	help?: string
	children: JSX.Element
}

export function FormField({ label, name, errors, help, children }: formFieldProps) {
	let helperText: JSX.Element = <span />
	let hasError = false

	if (errors !== undefined && name in errors) {
		hasError = true
		helperText = (
			<div style={{ margin: "2px 0 0 0" }}>
				<span style={{ fontWeight: 500, color: Colors.RED3 }}>
					{errors[name]}
				</span>
			</div>
		)
	} else if (help !== undefined) {
		helperText = (
			<div style={{ margin: "2px 0 0 0" }}>
				<span style={{ color: Colors.GRAY2 }}>{help}</span>
			</div>
		)
	}

	return (
		<div style={{ flex: 1, margin: "0 0 15px 0" }}>
			<div>
				<span
					style={{
						fontSize: "1em",
						fontWeight: 600,
						color: hasError ? Colors.RED3 : Colors.DARK_GRAY5
					}}>
					{label}
				</span>
			</div>
			<div style={{ margin: "2px 0 0 0" }}>
				{children}
			</div>
			{helperText}
		</div>
	)
}

export const FormSpace = () =>
	<div style={{ flex: "1 1 0%" }}></div>

export const FormLine: FunctionComponent<unknown> = ({ children }) =>
	<div style={{ display: "flex", gap: "10px" }}>
		{children}
	</div>

type formSubmitProps = {
	isSubmitting: boolean
	handleClose: () => void
	hasErrors: boolean
}

export const FormSubmit = ({ isSubmitting, handleClose, hasErrors }: formSubmitProps) => {
	if (isSubmitting) {
		return (
			<div style={{ display: "flex" }}>
				<Spinner intent="primary" size={18} />
				<div style={{ margin: "0 0 0 10px" }} className="bp3-text-large">
					Sending data
				</div>
			</div >
		)
	} else {
		return (
			<div>
				{hasErrors &&
					<div style={{ margin: "0 0 20px 0" }}>
						<Callout intent="danger" title="Errors detected">Fix the errors to continue</Callout>
					</div>
				}
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<div>
						<Button onClick={() => handleClose()}>
							Close
						</Button>
					</div>
					<div style={{ margin: "0 0 0 10px" }}>
						<Button type="submit" intent="primary">
							Save
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

type formPopUpState = {
	isOpen: boolean
	submitting: boolean
}

export interface IFormPopUpProps {
	resource: string
	data: any
}

export abstract class FormPopUp<Type extends IFormPopUpProps> extends Component<Type, formPopUpState>{

	state: formPopUpState = {
		isOpen: false,
		submitting: false,
	}

	handleOpen = () => this.setState({ isOpen: true });

	handleClose = () => {
		if (!this.state.submitting)
			this.setState({ isOpen: false });
	}

	handleDone() {
		this.handleClose()
	}

	handleSubmit = (submitting: boolean) => {
		this.setState({ submitting: submitting });
	}
}