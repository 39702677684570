import { format } from "date-fns"
import { get, post, put, queryClient } from "./client"

export namespace Workspaces {

	export const baseUrl = (workspaceID?: number, next?: string) => {
		let url = '/workspaces'
		if (workspaceID !== undefined) {
			url += `/${workspaceID}`
			if (next !== undefined) {
				url = `${url}/${next}`
			}
		}
		return url
	}

	export const Get = () => {
		return {
			queryKey: baseUrl(),
			queryFn: get('workspaces', baseUrl())
		}
	}

	export const Refresh = () => queryClient.invalidateQueries(baseUrl())

	export const Create = (body: any) => post('workspace', baseUrl(), body)

	// Workspace
	export namespace Workspace {

		export const Get = (workspaceID: number) => {
			return {
				queryKey: baseUrl(workspaceID),
				queryFn: get('workspace', baseUrl(workspaceID))
			}
		}

		export const Update = (wokspaceID: number, body: any) =>
			put('workspace', baseUrl(wokspaceID), body)

		export const Refresh = (workspaceID: number) =>
			queryClient.invalidateQueries(baseUrl(workspaceID))
	}

	export namespace Admins {
		export const url = (workspaceID: number) => baseUrl(workspaceID, 'admins')

		export const All = (workspaceID: number) => {
			return {
				queryKey: url(workspaceID),
				queryFn: get('admins', `${url(workspaceID)}`)
			}
		}

		export namespace Invitations {
			export const url = (workspaceID: number) => baseUrl(workspaceID, 'admins/invitations')

			export const All = (workspaceID: number) => {
				return {
					queryKey: url(workspaceID),
					queryFn: get('admin_invitation', `${url(workspaceID)}`)
				}
			}

			export const Create = (wokspaceID: number, body: any) =>
				post('admin_invitation', url(wokspaceID), body)

			export const Refresh = (workspaceID: number) =>
				queryClient.invalidateQueries(url(workspaceID))
		}
	}

	export namespace TimeOff {
		export const url = (workspaceID: number) => baseUrl(workspaceID, 'time_off')

		export const Listing = (workspaceID: number, month: Date) => {
			const monthParam = format(month, "y-LL")
			const dest = `${url(workspaceID)}?month=${monthParam}`
			return {
				queryKey: dest,
				queryFn: get('time_off_listing', dest)
			}
		}
	}
}