import React from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Status } from "../../client/client";
import { Workspaces } from "../../client/workspaces";
import { Index as Admin } from "../admin";
import { Loading, ErrorMessage } from "./helpers";

type params = {
	workspaceID: string
}

export default function ModeSelector() {
	const { workspaceID } = useParams<params>()
	if (workspaceID === undefined) {
		return <Navigate to={"/"} />
	}
	const { data, error, isFetching } = useQuery(Workspaces.Workspace.Get(parseInt(workspaceID)))

	if (isFetching) {
		return (<Loading />)
	} else if (error) {
		return (<ErrorMessage />)
	} else if (data?.status !== Status.Success) {
		return <Navigate to={"/"} />
	}

	const workspace = data?.data
	const { is_owner, admin_id } = workspace.user_access;
	if (is_owner || admin_id != 0) {
		return (<Admin workspace={workspace} />)
	} else {
		return (<div>employee page: not done yet</div>)
	}
}