import { Callout, Classes, Colors, Dialog, Spinner } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Banner } from "../common/banner";
import { User } from "../common/user";

type layoutProps = {
	title?: string
}

export const Layout: FunctionComponent<layoutProps> = ({ title, children }) =>
	<div>
		<div style={{ display: "flex", justifyContent: "flex-end" }}>
			<div style={{ margin: "26px 32px 0 0" }}>
				<User />
			</div>
		</div>
		<div style={{ display: "flex", justifyContent: "center", margin: "20px 0 200px 0" }}>
			<div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Banner large />
				</div>
				<div style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center",
					width: "100%",
					padding: "50px 0 20px 0"
				}}>
					{title !== null &&
						<h1 className="bp3-heading">{title}</h1>
					}
				</div>
				{children}
			</div>
		</div >
	</div>


export const Loading = () => (
	<Layout title="Loading">
		<div>
			<Subtitle subtitle="Please wait while the YeetPay app is loading" />
			<Spinner intent="primary" size={140} />
		</div>

	</Layout>
)

export const NotFound = () => (
	<Layout title="Ooops, page not found!">
		<div>
			<h3 className="bp3-heading" style={{ color: Colors.GRAY1 }}>
				The page you are looking for could not be found...
			</h3>
			<div className="has-text-centered">
				<Link to="/" className="button mt-6 is-medium is-primary is-outlined">
					Back to Workspaces
				</Link>
			</div>
		</div>
	</Layout>
)

export const AccessDenied = () => (
	<Layout title="You can't access this page">

		<div>
			<Subtitle subtitle="You dont have the permission to access this page. If you believe this is a mistake, try to contact a Workspace administrator." />
			<div className="has-text-centered">
				<Link to="/" className="button mt-6 is-medium is-primary is-outlined">
					Back to Workspaces
				</Link>
			</div>
		</div>
	</Layout>
)

export const ErrorMessage = () => (
	<Layout title="Ooops, something went wrong...">
		<Subtitle subtitle="We are experiencing some errors. Please try to refresh the page." />
	</Layout>
)

export const LoadingErrorPopup = () => (
	<Dialog isOpen usePortal>
		<div className={Classes.DIALOG_BODY}>
			<Callout intent="danger" title="Connection Errors">
				Some errors where detected while fetching data.
				Please check your internet connection and refresh the page.
				<br /><br />
				If the problem persists, please contact our support team.
			</Callout>
		</div>
	</Dialog>
)

export function Subtitle(props: { subtitle: string }) {
	return (
		<div style={{ display: "flex", justifyContent: "center" }}>
			<h3 className="bp3-heading" style={{ color: Colors.GRAY1 }}>
				{props.subtitle}
			</h3>
		</div>

	)
}