import { Button, Callout, Classes, Dialog, H3, HTMLTable, InputGroup, Tag, TextArea } from "@blueprintjs/core"
import { Form, Formik, FormikErrors } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Workspaces } from "../../../../client/workspaces"
import { ConfirmDelete } from "../../../common/confirm"
import { DateAgo, DateDisplay } from "../../../common/dates"
import { ErrorConnection } from "../../../common/errors"
import { FormField } from "../../../common/form"
import { Loading } from "../../../common/loading"
import { created } from "../../../common/toasts"

export function Invitations(props: { workspace: any }) {
	const { t } = useTranslation()

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<H3>{t("admin.settings.admins.invitations.invitations")}</H3>
				<NewInvitation workspace={props.workspace} />
			</div>
			<InvitationsListing workspace={props.workspace} />
		</div>
	)
}

function InvitationsListing(props: { workspace: any }) {
	const { data, isError, isLoading } = useQuery(Workspaces.Admins.Invitations.All(props.workspace.workspace_id))
	const { t } = useTranslation()

	if (isError) {
		return <ErrorConnection />
	}

	if (isLoading) {
		return <Loading dataName={t("admin.settings.admins.invitations.invitations")} />
	}

	if (data?.data !== null && data?.data !== undefined) {
		const d = data.data
		if (Array.isArray(d)) {
			return <Listing workspaceID={props.workspace.workspace_id} invitations={d} />
		}
	}

	return (
		<div>
			<Callout intent="primary" style={{ margin: "20px 0 0 0" }} title={t("admin.settings.admins.invitations.empty_title")}>
				{t("admin.settings.admins.invitations.empty")}
			</Callout>
		</div>
	)
}

function Listing(props: { workspaceID: number, invitations: Array<any> }) {
	const { t } = useTranslation()

	return (
		<HTMLTable style={{ width: 800, margin: "20px 0 0 0" }} striped condensed>
			<thead>
				<tr>
					<td>{t("admin.settings.admins.invitations.email")}</td>
					<td>{t("admin.settings.admins.invitations.created_on")}</td>
					<td>{t("admin.settings.admins.invitations.creator")}</td>
					<td></td>
				</tr>
			</thead>
			<tbody>
				{props.invitations.map(e =>
					<tr key={e.invitation_id}>
						<td>{e.email}</td>
						<td>
							<DateDisplay value={e.created_at} />
							<br />
							<div className="bp3-text-small bp3-text-muted">
								<DateAgo value={e.created_at} />
							</div>

						</td>
						<td>
							<div>{e.creator.first_name} {e.creator.last_name}</div>
							<div className="bp3-text-small bp3-text-muted">
								{e.creator.email}
							</div>
						</td>
						<td style={{ textAlign: "right" }}>
							<ConfirmDelete
								message={t("admin.settings.admins.invitations.confirm_delete")}
								deletedMessage={t("admin.settings.admins.invitations.deleted")}
								deleteUrl={`${Workspaces.Admins.Invitations.url(props.workspaceID)}/${e.invitation_id}`}
								resource={Workspaces.Admins.Invitations.url(props.workspaceID)}
							/>
						</td>
					</tr>
				)}
			</tbody>
		</HTMLTable>
	)
}

export function InvitationRoleTag(props: { invitation: any }) {
	if (props.invitation.role === "admin") {
		return <Tag intent="danger" minimal>Administrator</Tag>
	} else {
		return <Tag intent="none" minimal>Employee</Tag>
	}
}

function NewInvitation(props: { workspace: any }) {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false);

	return (
		<div>
			<Button intent="success" onClick={() => setOpen(true)}>
				{t("admin.settings.admins.invitations.new")}
			</Button>

			<Dialog
				isOpen={open}
				canEscapeKeyClose
				canOutsideClickClose
				onClose={() => setOpen(false)}
				title={t("admin.settings.admins.invitations.new")}>
				<div className={Classes.DIALOG_BODY} style={{ marginBottom: "0" }}>
					<InvitationForm workspace={props.workspace} onDone={() => setOpen(false)} />
				</div>
			</Dialog>
		</div >
	)
}

export function InvitationForm(props: { workspace: any, onDone: () => void }) {
	const { t } = useTranslation()

	return (
		<div>
			<Formik
				initialValues={{
					email: '',
					message: '',
					role: 'Employee'
				}}

				onSubmit={async (values, actions) => {
					const res = await Workspaces.Admins.Invitations.Create(props.workspace.workspace_id, values)
					if (res.validationErrors) {
						actions.setErrors(res.validationErrors)
					} else {
						created(
							t("admin.settings.admins.invitations.created"),
							"admin.settings.admins.invitation_created")
						Workspaces.Admins.Invitations.Refresh(props.workspace.workspace_id)
						props.onDone()
					}
				}}>

				{({
					values,
					errors,
					handleChange,
					isSubmitting,
				}) => (
					<Fields values={values} handleChange={handleChange} isSubmitting={isSubmitting} errors={errors} />
				)}
			</Formik>
		</div >
	)
}


type props = {
	values: any
	handleChange: (e: React.ChangeEvent<any>) => void
	isSubmitting: boolean
	errors: FormikErrors<any>

}

function Fields({ values, handleChange, isSubmitting, errors }: props) {
	const { t } = useTranslation()

	return (
		<Form>
			<div style={{ display: "flex" }}>
				<FormField label="Email" name="email" errors={errors}>
					<InputGroup
						id="email"
						fill
						value={values.email}
						onChange={handleChange}
					/>
				</FormField>
			</div>
			<div style={{ display: "flex" }}>
				<FormField label="Message" name="message" errors={errors}>

					<TextArea
						id="message"
						value={values.message}
						onChange={handleChange}
						fill />
				</FormField>
			</div>
			<div style={{ display: "flex", justifyContent: "right", margin: "20px 0 0 0" }}>
				<Button type="submit" intent="success" disabled={isSubmitting}>
					{t("admin.settings.admins.invitations.send")}
				</Button>
			</div>
		</Form>
	)
}
