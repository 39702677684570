import { DateInput } from "@blueprintjs/datetime"
import { format, formatDistanceStrict, isValid, parseISO } from "date-fns";
import { formatDistance } from "date-fns/esm";

type props = {
	value: string
	onChange: (date: string) => void
	maxDate?: Date
	minDate?: Date
}

export function DateField({ value, onChange, maxDate, minDate }: props) {
	return <DateInput
		formatDate={date => date.toLocaleDateString()}
		parseDate={str => new Date(str)}
		highlightCurrentDay
		placeholder="DD/MM/YYYY"
		fill
		maxDate={maxDate}
		minDate={minDate}
		onChange={(d) => {
			if (d !== undefined && isValid(d)) {
				onChange(format(d, 'yyyy-MM-dd'))
			} else {
				onChange('')
			}

		}}
		value={value !== undefined && value !== '' ? new Date(value) : undefined}
	/>
}


type monthDisplayProps = {
	value: string | Date
}

export function MonthDisplay({ value }: monthDisplayProps) {
	let date: Date
	if (value instanceof Date) {
		date = value
	} else {
		date = parseISO(value);
	}

	return <span>{format(date, "MMMM yyyy")}</span>
}

type dateDisplayProps = {
	value: string | Date
	words?: boolean
}

export const DateDisplay = ({ value, words }: dateDisplayProps) => {
	let date: Date

	if (value instanceof Date) {
		date = value
	} else {
		date = parseISO(value);
	}

	if (!isValid(date)) {
		return <span>Invalid date</span>
	}

	if (words) {
		return <span>{format(date, "EEEE MMMM dd yyyy")}</span>
	}

	return <span>{format(date, "dd/MM/yyyy")}</span>
}

export const DateTimeDisplay = (props: { value: string }) => {
	const d = parseISO(props.value);
	if (!isValid(d)) {
		return <span>Invalid date</span>
	}

	return <span>{format(d, "dd/MM/yyyy HH:mm")}</span>
}

export const DateAgo = (props: { value: string, strict?: boolean }) => {
	const d = parseISO(props.value);
	if (!isValid(d)) {
		return <span>Invalid date</span>
	}

	let dist: string
	if (props.strict !== undefined && props.strict) {
		dist = formatDistanceStrict(d, new Date)
	} else {
		dist = formatDistance(d, new Date, { addSuffix: true })
	}

	return <span>{dist}</span>
}

