import { Button, ButtonGroup, Dialog, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { useEffect } from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useAppState } from "../../../App";
import { User as UserApi } from "../../../client/user";
import { LoadingErrorPopup } from "../../init/helpers";
import { Loading } from "../loading";

export const User: FunctionComponent = () => {
	const { user } = useAppState()
	if (user === null) {
		return <div />
	}

	const [openLang, setLangOpen] = useState(false)

	const menu = (
		<Menu>
			<MenuItem text="Submenu" />
			<MenuItem text="Change Language" onClick={() => setLangOpen(true)} />
			<MenuDivider />
			<MenuItem intent="danger" text="Log out" href={user.logout_url} />
		</Menu>
	)
	return (
		<>
			<Popover2 content={menu} placement="bottom-end">
				<Button minimal large icon="user">
					{user.first_name} {user.last_name}
				</Button>
			</Popover2>

			<SetLanguage isOpen={openLang} setOpen={setLangOpen} />
		</>
	)
}

export const FetchUser: FunctionComponent = () => {
	const { setUser } = useAppState()
	const { data, error, isFetching } = useQuery(UserApi.Profile.Get())
	const { i18n } = useTranslation()

	useEffect(() => {
		if (!isFetching && !error) {
			if (data !== null && data !== undefined) {
				setUser(data?.data)
				if (i18n.language !== data?.data.language) {
					i18n.changeLanguage(data?.data.language)
				}
			}
		}
	})

	if (error) {
		return <LoadingErrorPopup />
	} else {
		return <span />
	}
}

type languageProps = {
	setOpen: (open: boolean) => void
	isOpen: boolean
}

const SetLanguage: FunctionComponent<languageProps> = ({ setOpen, isOpen }) => {
	const [loading, setLoading] = useState(false)
	const queryClient = useQueryClient()

	const setLanguage = async (code: string) => {
		setLoading(true)
		await UserApi.Profile.SetLanguage(code)
		setLoading(false)
		setOpen(false)
		queryClient.invalidateQueries(UserApi.Profile.url)
	}

	return (
		<Dialog
			title="Change Language"
			style={{ maxWidth: 350 }}
			isOpen={isOpen}
			usePortal
			canEscapeKeyClose={!loading}
			onClose={() => setOpen(false)}
			canOutsideClickClose={!loading}>

			<div style={{ margin: "20px 10px 20px 10px", display: "flex", justifyContent: "center" }}>
				{!loading &&
					<ButtonGroup vertical alignText="left" large>
						<Button onClick={() => setLanguage("en")}>English</Button>
						<Button onClick={() => setLanguage("cs")}>Czech čeština</Button>
					</ButtonGroup>
				}
				{loading &&
					<Loading text="Changing language" />
				}
			</div>
		</Dialog>
	)
}