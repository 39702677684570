import { get, post, put, queryClient } from "./client"

export namespace User {

	const baseUrl = (next?: string) => {
		let url = '/user'
		if (next !== undefined) {
			url = `${url}/${next}`
		}
		return url
	}

	export namespace Profile {
		export const url = baseUrl('profile')

		export const Get = () => {
			return {
				queryKey: url,
				queryFn: get('profile', url)
			}
		}

		export const SetLanguage = (lang: string) =>
			put('profile', url + '/lang', { lang })

		export const Refresh = () =>
			queryClient.invalidateQueries(url)
	}

	export namespace Invitations {
		export const url = baseUrl('invitations')

		export const All = () => {
			return {
				queryKey: url,
				queryFn: get('invitation', url)
			}
		}

		export const Refresh = () =>
			queryClient.invalidateQueries(url)

		export const Accept = (invitationID: number) =>
			post('invitation', `${url}/${invitationID}/accept`)

		export const Refuse = (invitationID: number) =>
			post('invitation', `${url}/${invitationID}/refuse`)
	}
}