import { IconNames } from "@blueprintjs/icons";
import React, { FunctionComponent, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { Layout, TabNavItem } from "../common/layout";
import { LoadingPage } from "../common/loading";

const NotFound = () => <span>Ooops, page not found!</span>

type props = {
	workspace: any
}

const Dashboard = lazy(() => import("./dashboard"))
const Employees = lazy(() => import("./employees/index"))
const TimeOff = lazy(() => import("./time_off"))
const Settings = lazy(() => import("./settings/index"))


export const Index: FunctionComponent<props> = ({ workspace }) => (
	<div>
		<Layout workspace={workspace} tabs={<MenuTabs />}>
			<div style={{ flexGrow: 1 }}>
				<Suspense fallback={<LoadingPage />}>
					<Routes>
						<Route path="" element={<Dashboard workspace={workspace} />} />
						<Route path="employees/*" element={<Employees workspace={workspace} />} />
						<Route path="payroll/*" element={<LoadingPage />} />
						<Route path="time_off/*" element={<TimeOff workspace={workspace} />} />
						<Route path="settings/*" element={<Settings workspace={workspace} />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>

			</div>

		</Layout >
	</div>
)


function MenuTabs() {
	const { t } = useTranslation()

	return (
		<div>
			<div className="bp3-tabs ">
				<ul className="bp3-tab-list bp3-large" role="tablist">
					<TabNavItem to="" path={""} name={t("admin.index.dashboard")} icon={IconNames.DASHBOARD} />
					<TabNavItem to="employees" path="employees/*" name={t("admin.index.employees")} icon={IconNames.PEOPLE} />
					<TabNavItem to="payroll" path="payroll/*" name={t("admin.index.payroll")} icon={IconNames.BANK_ACCOUNT} />
					<TabNavItem to="time_off" path="time_off/*" name={t("admin.index.time_off")} icon={IconNames.AIRPLANE} />
					<TabNavItem to="settings" path="settings/*" name={t("admin.index.settings")} icon={IconNames.COG} />
				</ul>
			</div>
		</div>
	)
}

