import { QueryClient } from "react-query"

export const V1 = process.env.REACT_APP_BASE_API + '/v1'

export enum Status {
	Success = "success",
	Fail = "fail",
	Error = "error",
}

type ResponseHeader = {
	statusCode: number
}

export type ApiResponse = {
	header: ResponseHeader
	status: Status
	message: string
	msgType?: string
	data?: any
}

const parseApiResponse = async (msg: string, response: Response) => {
	const body = await response.json()
	const res: ApiResponse = {
		header: { statusCode: response.status },
		status: body.status,
		message: body.message,
	}
	if (msg !== '') {
		if (body.data?.[msg]) {
			res.data = body.data?.[msg]
			res.msgType = msg
		}
	}

	return res
}

export type WriteResponse = {
	header: ResponseHeader
	status: string
	message: string
	msgType?: string
	data?: any
	validationErrors?: any
}

const parseWriteResponse = async (msg: string, response: Response) => {
	const body = await response.json()
	const res: WriteResponse = {
		header: { statusCode: response.status },
		status: body.status,
		message: body.message,
	}
	if (msg !== '') {
		if (body.data?.[msg]) {
			res.data = body.data?.[msg]
			res.msgType = msg
		}
	}
	if (res.status === Status.Fail || body.data?.['validation_errors']) {
		res.validationErrors = body.data?.['validation_errors']
	}
	return res
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},

})


export const get = (msg: string, url: string) => {
	return async () => {
		const response = await fetch(V1 + url, {
			method: 'GET',
			credentials: "include",
			headers: new Headers({ 'Accept': 'application/json' }),
		})

		if (!response.ok) {
			if (response.status === 401 || response.status === 403) {
				window.location.replace(process.env.REACT_APP_BASE_API + '/auth/login')
			}

			if (response.status === 404) {
				const res = await parseApiResponse(msg, response)
				return res
			}

			throw new Error('Network response was not ok')

		} else {
			const res = await parseApiResponse(msg, response)
			return res
		}
	}
}

export const remove = async (url: string) => {
	const rqst: RequestInit = {
		method: 'DELETE',
		credentials: "include",
		headers: new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}),
	}

	const response = await fetch(V1 + url, rqst)

	if (!response.ok) {
		if (response.status === 401 || response.status === 403) {
			window.location.replace(process.env.REACT_APP_BASE_API + '/auth/login')
		}
		if (response.status === 400) {
			return await response
		}

		throw new Error('Network response was not ok')
	} else {
		return await response
	}
}

export const post = async (msg: string, url: string, body?: any) => update('POST', msg, url, body)

export const put = async (msg: string, url: string, body?: any) => update('PUT', msg, url, body)

const update = async (method: string, msg: string, url: string, body?: any) => {
	const rqst: RequestInit = {
		method: method,
		credentials: "include",
		headers: new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}),
	}
	if (body !== undefined) {
		rqst.body = JSON.stringify(body)
	}

	const response = await fetch(V1 + url, rqst)

	if (!response.ok) {
		if (response.status === 401 || response.status === 403) {
			window.location.replace(process.env.REACT_APP_BASE_API + '/auth/login')
		}
		if (response.status === 400) {
			const res = await parseWriteResponse(msg, response)
			return res
		}

		throw new Error('Network response was not ok')
	} else {
		const res = await parseWriteResponse(msg, response)
		return res
	}
}